<!--
 * @Description: 驱动管理 - 驱动列表
 * @Author: kecraft
 * @Date: 2024-02-27 08:53:42
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-29 09:10:47
 * @FilePath: /impact-iotos-console/src/views/drive/list/driveManagement.vue
-->
<template>
  <div class="drive-homePage">
    <div>
      <div class="drive-box">
        <div class="big-title">
          <div></div>
          驱动列表
        </div>
        <div class="search-container">
          <div class="form-item">
            <el-input v-model="query.name" class="input-item" placeholder="请输入驱动名称" :prefix-icon="Search" />
            <div class="search-btn" @click="search" v-domPreventReClick>搜索</div>
            <div class="reset-btn" @click="reset" v-domPreventReClick>重置</div>
          </div>
          <div class="btn-green" @click="add" v-domPreventReClick>
            <img src="@/assets/icon/add.png">
            新增驱动
          </div>
          <div class="info-btn" @click="() => isLook = true" v-domPreventReClick>
            <span>?</span>
            查看说明
          </div>
        </div>

        <div class="tables">
          <el-table stripe :data="driveList" empty-text="暂无数据" v-loading="isload" style="width: 100%;">
            <el-table-column width="80" label="序号" align="center">
              <template #default="scope">
                <span>{{
              scope.$index + 1
            }}</span>
              </template>
            </el-table-column>
            <el-table-column min-width="150" label="驱动名称" prop="name" align="center" show-overflow-tooltip />
            <el-table-column width="130" label="驱动版本" prop="version" align="center" show-overflow-tooltip />
            <el-table-column width="130" label="部署模式" prop="deployModeStr" align="center" />
            <el-table-column min-width="130" label="上传日期" prop="createdDate" align="center" />
            <el-table-column min-width="130" label="更新日期" prop="updatedDate" align="center" />
            <el-table-column width="380" label="操作" align="center">
              <template #default="scope">
                <div class="column-btn">
                  <div class="options">
                    <span link style="color:#015ee0;cursor: pointer;padding: 0 6px;" @click="handleLook(scope.row)"
                      v-domPreventReClick>
                      查看
                    </span>
                    <span link style="color:#015ee0;cursor: pointer;padding: 0 6px;" @click="handleEdit(scope.row)"
                      v-domPreventReClick>
                      编辑
                    </span>
                    <span link style="color:#fc2b2b;cursor: pointer;padding: 0 6px;" @click="handleDel(scope.row)"
                      v-domPreventReClick>
                      删除
                    </span>
                    <span link style="color:#015ee0;cursor: pointer;padding: 0 6px;" @click="handleRun(scope.row)"
                      v-domPreventReClick>
                      启动
                    </span>
                    <span link style="color:#015ee0;cursor: pointer;padding: 0 6px;" @click="handleImprot(scope.row)"
                      v-domPreventReClick>
                      导入产品
                    </span>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="fooner-conatiner">
          <pagination :total="total" @pageChange="getList" class="pagination" />
        </div>
        <driveLook v-if="isLook" @close="() => isLook = false" />
        <taskAdd v-if="isTaskAdd" @close="() => isTaskAdd = false" ref="taskAddRef" />
      </div>
    </div>
  </div>
</template>

<script setup>
import taskAdd from "../task/taskAdd";
import pagination from "@/components/control/pagination";
import driveLook from "./driveLook";
import { Search } from '@element-plus/icons-vue';
import { onMounted, reactive, ref, getCurrentInstance, nextTick } from 'vue';
import { ElMessage, ElMessageBox } from "element-plus";
import { useRouter } from "vue-router";
import { ElLoading } from 'element-plus'
import api from "@/api/api"
const router = useRouter()
const driveList = ref([]);
const isLook = ref(false);
const isTaskAdd = ref(false);
const isload = ref(false);
const total = ref(0);
let currentInstance = "";
onMounted(() => {
  currentInstance = getCurrentInstance();
})
const query = reactive({
  name: "",
  page: 0,
  size: 10,
})
const getList = (v) => {
  query.page = v.pageNum - 1
  query.size = v.pageSize
  search();
}

const reset = () => {
  query.name = "";
  search();
}
const enums = [
  {
    label: "单机模式",
    value: "STANDALONE"
  },
  {
    label: "集群模式",
    value: "CLUSTER"
  },
  {
    label: "广播模式",
    value: "BROADCAST"
  }
]
const search = () => {
  isload.value = true;
  api.getDriversByPage(query).then(res => {
    if (res.code === "0") {
      const { content, totalElements } = res.res.data;
      content.forEach(item => {
        enums.forEach(e => {
          if (item.deployMode == e.value) {
            item.deployModeStr = e.label;
          }
        })
      })
      driveList.value = content;
      total.value = totalElements;
      isload.value = false;
    }
  })
}
search();
const handleLook = (row) => {
  router.push(
    {
      path: "/drive/add",
      query: { type: "view", driveId: row.id }
    }
  )
}
const handleEdit = (row) => {
  router.push(
    {
      path: "/drive/add",
      query: { type: "edit", driveId: row.id }
    }
  )
}
const handleDel = (row) => {
  ElMessageBox.confirm("确定删除该驱动吗？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    api.delDriverById(row.id).then(({ code }) => {
      if (code == "0") {
        ElMessage.success("删除成功!")
        search()
      }
    })
  })
}

const handleRun = (row) => {
  isTaskAdd.value = true;
  nextTick(() => {
    currentInstance.proxy.$refs.taskAddRef.init(row);
  });

}
const handleImprot = (row) => {
  ElMessageBox.confirm("确定从驱动导入产品信息吗？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    const loading = ElLoading.service({
      lock: true,
      text: 'Loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    api.importPorductsFromDriver(row.id).then(({ code }) => {
      if (code == "0") {
        ElMessage.success("导入成功!")
      }
      loading.close();
    })
  })
}

const add = () => {
  router.push(
    {
      path: "/drive/add",
      query: { type: "add" }
    }
  )
}
</script>

<style lang="less" scoped>
.info-btn {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  font-size: 16px;
  color: #333333;
  top: -30px;
  cursor: pointer;

  span {
    display: inline-block;
    text-align: center;
    line-height: 14px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    border: 1px solid #2483F5;
    font-size: 12px;
    color: #2483F5;
    margin-right: 10px;
  }
}

.btn-green {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  height: 36px;
  border-radius: 4px;
  background: #30AE7D;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: lighter;
  cursor: pointer;

  &>img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
}

.search-btn {
  margin-left: 20px;
  cursor: pointer;
  width: 92px;
  height: 36px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  background: #367CC5;
  font-weight: lighter;
  // border: 1px solid #015ee0;
  font-size: 14px;
  color: #FFFFFF;
  font-size: 16px;
}

.search-btn:hover,
.search-btn:active {
  border: 1px solid #367CC5;
  background: #61A4E9;
  // color: #367CC5;
}

.reset-btn {
  margin-left: 20px;
  cursor: pointer;
  width: 92px;
  height: 36px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  font-weight: lighter;
  border: 1px solid #AFB9CB;
  color: #333333;
  font-size: 16px;
}

.big-title {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #367CC5;
  margin-bottom: 20px;

  &>div {
    width: 3px;
    height: 17px;
    background: #367CC5;
    margin-right: 7px;
  }
}

.drive-homePage {
  box-sizing: border-box;

  &>div {
    min-height: calc(100vh - 76px);
    background: #FAFAFB;
    box-sizing: border-box;
    padding: 10px;
    padding-top: 0;

    .drive-box {
      width: 100%;
      height: calc(100vh - 76px);
      background: #ffffff;
      box-sizing: border-box;
      padding: 18px 40px;
    }
  }

  .search-container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .form-item {
      display: flex;
      align-items: center;
    }

    .input-item {
      width: 260px;
    }
  }

  .fooner-conatiner {
    box-sizing: border-box;
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;
  }
}


.tables {
  box-sizing: border-box;
  padding-top: 20px;
}

:deep(.el-table th.el-table__cell) {
  background-color: #F6F7F9;
  height: 50px;
  font-weight: normal;
  color: #000000;
}

:deep(.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell) {
  background-color: #FBFBFB;
}

:deep(.el-table td.el-table__cell div) {
  font-size: 16px;
  line-height: 50px;
}
</style>