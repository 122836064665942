<!--
 * @Description: 驱动列表 - 查看说明
 * @Author: kecraft
 * @Date: 2024-02-27 09:45:28
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-27 15:57:58
 * @FilePath: /impact-iotos-console/src/views/drive/list/driveLook.vue
-->
<template>
  <div class="drive-look">
    <myDialog @close="handleClose" @ok="handleOk" title="驱动说明">
      <div class="look-box">
        <div class="look-info">
          <div class="imgs">
            <img src="@/assets/drive/success.png">
            <img src="@/assets/drive/success.png">
            <img src="@/assets/drive/success.png">
          </div>
          <div>
            <p>驱动包必须包含一个网关产品信息。</p>
            <p>驱动包如果没有jar文件，则不能启动驱动实例。</p>
            <p>驱动程序打印日志不能太频繁，如果打印太多日志，会占用比较多的存储空间，并且查看实时日志会造成页面卡顿，建议只打印重要信息。</p>
          </div>
        </div>
        <div class="bottom-container">
          <div class="big-title">部署模式</div>
          <div><span class="sub-title">单机模式：</span>驱动只能启动1个实例</div>
          <div><span class="sub-title">集群模式：</span>驱动支持集群部署（启动多个任务实例），但是给设备发送的消息只能由其中一个实例接收处理</div>
          <div><span class="sub-title">广播模式：</span>驱动支持集群部署（启动多个任务实例），给设备发送的消息所有实例都可以接收并处理</div>
        </div>
      </div>
    </myDialog>
  </div>
</template>

<script setup>
import myDialog from "@/components/dialog/myDialog.vue";
import { defineEmits } from "vue";
const emit = defineEmits(["close"]);
const handleClose = () => {
  emit("close")
}
const handleOk = () => {
  emit("close")
}
</script>

<style lang="less" scoped>
.look-box {
  width: 700px;

  .look-info {
    display: flex;
    color: #606266;

    p {
      line-height: 19px;
      margin-bottom: 8px;
    }

    .imgs {
      display: flex;
      flex-direction: column;

    }

    img:first-child {
      margin-top: 2px;
    }

    img {
      width: 17px;
      height: 17px;
      margin-bottom: 8px;
      margin-right: 11px;
    }
  }
}

.bottom-container {
  margin-top: 40px;

  .big-title {
    color: #606266;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 8px;
  }
  color: #606266;
  line-height: 22px;
  .sub-title {
    color: #606266;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 8px;
  }
}
</style>